.Streams {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  padding-left: 10px;
}

.card {
  width: 180px;
  height: 180px;
  perspective: 1000px;
  margin-right: 10px;
  margin-bottom: 10px;
}

  
  .front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    line-height: 100px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .front {
    color: transparent;
    background-size: cover; /* 이미지가 카드의 크기에 맞게 조절됩니다 */
    background-position: center; /* 이미지가 중앙에 위치하도록 합니다 */
    overflow: hidden; /* 이미지가 카드 밖으로 넘어가지 않게 합니다 */  
  }
  
  .back {
    font-size: 3.6rem;
    font-weight: 900;
    background: #f9f9f9;
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  
  .flipped .front {
    transform: rotateY(180deg);
  }
  
  .flipped .back {
    transform: rotateY(0deg);
  }
  